import { useEffect, useRef } from "react";

export function useInterval(callback, delay, enabled) {
  const callbackRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (enabled) {
      const interval = setInterval(() => callbackRef.current(), delay);
      return () => clearInterval(interval);
    }
  }, [delay, enabled]);
}
