import React, { memo, useEffect, useRef } from "react";
import { useBoard } from "./useBoard";

const Board = () => {
  const [display, score, onKeyDown, state, start, pause, resume, pressKey] =
    useBoard();
  const eBoard = useRef();

  useEffect(() => eBoard.current.focus(), [state]);

  return (
    <div ref={eBoard} className={"t-board"} tabIndex={0} onKeyDown={onKeyDown}>
      <div className="t-score-head">
        <span>Score:&nbsp;</span>
        <span className="t-score-label">{score.toLocaleString()}</span>

        {/* {state === "running" && (
          <button className="t-score-button" onClick={pause}>
            Pause
          </button>
        )}
        {state === "paused" && (
          <button className="t-score-button" onClick={resume}>
            Resume
          </button>
        )} */}
      </div>
      {state === "end" && (
        <div className="t-score-modal">
          <div className="t-score-modal-title">Game Over</div>

          <button className="t-score-button" onClick={start}>
            Start New Game
          </button>
        </div>
      )}
      {display.map((row, index) => (
        <Row row={row} key={index} />
      ))}
      <div className="controls">
        <div>
          <button onClick={(evt) => pressKey("ArrowUp", evt)}>&uarr;</button>
        </div>
        <button onClick={(evt) => pressKey("ArrowLeft", evt)}>&larr;</button>
        <button onClick={(evt) => pressKey("ArrowDown", evt)}>&darr;</button>
        <button onClick={(evt) => pressKey("ArrowRight", evt)}> &rarr;</button>
      </div>
    </div>
  );
};

const Row = memo((props) => {
  return (
    <span className="t-row">
      {props.row.map((cell, index) => (
        <Cell cell={cell} key={index} />
      ))}
    </span>
  );
});

const Cell = memo((props) => {
  const count = useRef(0);

  count.current++;

  const value = props.cell ? props.cell[0] : 0;
  return (
    <span
      className={`t-cell t-cell-${value}`}
      style={{ backgroundColor: props.cell[1] }}
    ></span>
  );
});

export default memo(Board);
