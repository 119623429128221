import "./styles.css";
import Board from "./tetris/board";

export default function App() {
  return (
    <div className="t-parent">
      <Board />
    </div>
  );
}
