const SHAPES = [
  {
    shape: [
      { x: 1, y: 0 },
      { x: 2, y: 0 },
      { x: 1, y: 1 },
      { x: 0, y: 1 },
      //
    ],
    color: "#70AC47",
    width: 3,
    height: 2,
    rotate: true,
  },
  {
    shape: [
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 2, y: 0 },
      { x: 0, y: 1 },
      //
    ],
    color: "#305495",
    width: 3,
    height: 2,
    rotate: true,
  },
  {
    shape: [
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 2, y: 0 },
      { x: 2, y: 1 },
      //
    ],
    color: "#9933FF",
    width: 3,
    height: 2,
    rotate: true,
  },
  {
    shape: [
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 2, y: 0 },
      { x: 1, y: 1 },
      //
    ],
    color: "#FFC100",
    width: 3,
    height: 2,
    rotate: true,
  },
  {
    shape: [
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 1, y: 1 },
      { x: 2, y: 1 },
      //
    ],
    color: "#FF6600",
    width: 3,
    height: 2,
    rotate: true,
  },
  {
    shape: [
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 1, y: 1 },
      { x: 0, y: 1 },
      //
    ],
    color: "#FEFF66",
    width: 2,
    height: 2,
    rotate: false,
  },
  {
    shape: [
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 2, y: 0 },
      { x: 3, y: 0 },
      //
    ],
    color: "#5B9BD5",
    width: 4,
    height: 1,
    rotate: true,
  },
];

export function randomShape() {
  return SHAPES[Math.floor(Math.random() * SHAPES.length)];
}
